@import url(https://fonts.googleapis.com/css?family=Space+Mono);
@font-face {
  font-family: 'EuclidCircularB';
  src: url(/static/media/EuclidCircularB-Light.5ca775d5.woff2) format('woff2'),
    url(/static/media/EuclidCircularB-Light.bac2802a.woff) format('woff'),
    url(/static/media/EuclidCircularB-Light.f1c05ed3.ttf) format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url(/static/media/EuclidCircularB-Regular.90b7f1a7.woff2) format('woff2'),
    url(/static/media/EuclidCircularB-Regular.bdc3309d.woff) format('woff'),
    url(/static/media/EuclidCircularB-Regular.0604e1b3.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url(/static/media/EuclidCircularB-Medium.2985e23c.woff2) format('woff2'),
    url(/static/media/EuclidCircularB-Medium.3111ec93.woff) format('woff'),
    url(/static/media/EuclidCircularB-Medium.53a8bc4a.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url(/static/media/EuclidCircularB-Semibold.8232876d.woff2) format('woff2'),
    url(/static/media/EuclidCircularB-Semibold.568ed96e.woff) format('woff'),
    url(/static/media/EuclidCircularB-Semibold.d1dfddab.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url(/static/media/EuclidCircularB-Bold.0b527754.woff2) format('woff2'),
    url(/static/media/EuclidCircularB-Bold.fb18e827.woff) format('woff'),
    url(/static/media/EuclidCircularB-Bold.d53c28db.ttf) format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Tiempos Text';
  src: url(/static/media/TiemposText-Regular.caff3dee.woff2) format('woff2'),
    url(/static/media/TiemposText-Regular.6e58d0e8.woff) format('woff'),
    url(/static/media/TiemposText-Regular.3b0b817a.ttf) format('truetype');
  font-weight: 400;
}

:root {
  font-family: EuclidCircularB, Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  text-align: left;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
:before,
:after {
  box-sizing: inherit;
}

img {
  display: block;
}

caption,
th,
td {
  text-align: inherit;
}

html,
body,
th,
td {
  padding: 0;
}

html,
body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul,
dl,
dd,
figure,
blockquote,
hr,
input,
button,
select,
textarea {
  margin: 0;
}
tt,
code,
kbd,
samp,
pre {
  font-family: code, monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
th,
em,
cite,
strong,
small,
input,
button,
select,
text-area {
  font-style: inherit;
  font-size: inherit;
  font-weight: inherit;
}

ol,
ul {
  list-style: none;
  padding-left: 0;
}

table {
  border-collapse: collapse;
}

s,
strike,
del,
ins {
  text-decoration: inherit;
}

input,
button,
select,
textarea {
  font-family: inherit;
  line-height: inherit;
}

[type='date'],
[type='email'],
[type='number'],
[type='password'],
[type='search'],
[type='tel'],
[type='url'],
[type='text'],
[type='button'],
[type='submit'],
[type='reset'],
button,
textarea {
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
  border-width: 0;
  padding: 0;
}

[type='button'],
[type='submit'],
[type='reset'],
button {
  cursor: pointer;
  text-align: inherit;
}

.lucide {
  stroke-width: 1.5px;
}

button[data-close-button='true'] {
  background-color: white;
}

